import React from 'react';
import { getLcn } from 'web/utils/labeled-classnames';

// Lives at top of app
// Holds Drawers which will cover the entire screen
// use with createPortal to render Drawers inside
export function Dresser() {
  const lcn = getLcn({
    dresser: 'z-1 pointer-events-none fixed top-0 h-screen w-screen overflow-hidden',
  });

  return <div id="dresser" className={lcn.dresser}></div>;
}
