import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit';

import readerReducer from './reader/slice';
import settingsSlice from './settings/slice';
import featureFlagsReducer from './feature-flags/slice';
import firebaseReducer from './firebase/slice';

// Create the root reducer separately so we can extract the RootState type
// Don't forget to add changes to TestUtils if you add a new reducer
const rootReducer = combineReducers({
  reader: readerReducer,
  settings: settingsSlice,
  featureFlags: featureFlagsReducer,
  firebase: firebaseReducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        // Needed to allow `Content` type from epubjs to be serialized
        serializableCheck: {
          ignoredActions: ['reader/setDomSelection', 'reader/setMenuDomSelection'],
          ignoredPaths: [
            'reader.domSelection.contents',
            'reader.menuDomSelection.contents',
            'reader.menuDomSelection.portalNode',
          ],
        },
      }),
  });
}

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;

export default store;
