import constants from 'erb/rendered/rails-constants';

declare global {
  interface Window {
    _APP_: {
      ENV: AppEnv;
    };
  }
}

export enum AppEnv {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
  Test = 'test',
}

export const APP_ENV = window._APP_?.ENV;

export const isDevelopment = APP_ENV === AppEnv.Development;
export const isProduction = APP_ENV === AppEnv.Production;
export const isStaging = APP_ENV === AppEnv.Staging;

export const SENTRY_DSN = constants[APP_ENV]?.SENTRY_DSN;
export const BRANCH_API_KEY = constants[APP_ENV]?.BRANCH_API_KEY;

export const CIRCLE_VISIBILITY_TYPES = {
  PUBLIC: 'public',
  OPEN: 'open',
  SOLO: 'solo',
  PRIVATE: 'private',
};

export const PUBLIC_CIRCLE_VISIBILITY_TYPES = [
  CIRCLE_VISIBILITY_TYPES.PUBLIC,
  CIRCLE_VISIBILITY_TYPES.OPEN,
];

export const ROLES = {
  OWNER: 'Owner',
  CO_OWNER: 'Co-Owner',
  MEMBER: 'Member',
};

export const LEADER_ROLES = [ROLES.OWNER, ROLES.CO_OWNER];

export const IMAGEKIT_WIDTH_TRANSFORMS = {
  36: 'if-w_lt_36,w-auto,if-else,w-36,if-end',
  50: 'if-w_lt_50,w-auto,if-else,w-50,if-end',
  100: 'if-w_lt_100,w-auto,if-else,w-100,if-end',
  200: 'if-w_lt_200,w-auto,if-else,w-200,if-end',
  400: 'if-w_lt_400,w-auto,if-else,w-400,if-end',
  600: 'if-w_lt_600,w-auto,if-else,w-600,if-end',
  800: 'if-w_lt_800,w-auto,if-else,w-800,if-end',
  1000: 'if-w_lt_1000,w-auto,if-else,w-1000,if-end',
  1500: 'if-w_lt_1500,w-auto,if-else,w-1500,if-end',
  2000: 'if-w_lt_2000,w-auto,if-else,w-2000,if-end',
};

export const IMAGE_SIZE_MAP = {
  xs: IMAGEKIT_WIDTH_TRANSFORMS[100],
  sm: IMAGEKIT_WIDTH_TRANSFORMS[100],
  base: IMAGEKIT_WIDTH_TRANSFORMS[100],
  md: IMAGEKIT_WIDTH_TRANSFORMS[100],
  lg: IMAGEKIT_WIDTH_TRANSFORMS[400],
  xl: IMAGEKIT_WIDTH_TRANSFORMS[600],
  '2xl': IMAGEKIT_WIDTH_TRANSFORMS[1000],
  full: IMAGEKIT_WIDTH_TRANSFORMS[1000], //backwards compatibility
  '3xl': IMAGEKIT_WIDTH_TRANSFORMS[1500],
  '4xl': IMAGEKIT_WIDTH_TRANSFORMS[2000],
};

export const REPLY_PAGINATION_LIMIT = 10;
export const IMAGEKIT_BASE_URL = 'https://ik.imagekit.io/threadable';
export const DEFAULT_COVER_IMAGE_URL = `${IMAGEKIT_BASE_URL}/logo_M7bJe0B1k.png`;
export const MARKETING_SITE_IMAGEKIT_BASE_URL = `${IMAGEKIT_BASE_URL}/marketing-site`;
export const EPUB_ANNOTATION_UNDERLINE = 'data-annotation-underline';
