import firebase from 'firebase/app';
import * as Sentry from '@sentry/browser';
import { useCustomToken } from 'web/api/session';
import trackError from 'shared/utils/errors/track-error';

const useLoginUserFromIdToken = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const { mutateAsync: fetchCustomToken } = useCustomToken();

  const loginUserFromIdToken = async () => {
    try {
      const tokenFromQuery = queryParams.get('idToken');
      if (tokenFromQuery) {
        const { custom_token } = await fetchCustomToken(tokenFromQuery);
        if (custom_token) {
          await firebase.auth().signOut();
          firebase.auth().signInWithCustomToken(custom_token);
        }
      }
    } catch (e) {
      trackError(new Error('Error logging in user from id token', { cause: e }));
    }
  };

  return { loginUserFromIdToken };
};

export default useLoginUserFromIdToken;
