import { captureException } from '@sentry/react';

// Note: variables in js catch blocks can by any type, which allows one to catch any type of error
// whether it's an Error object, a string, or any other value.
export default function trackError(error: unknown) {
  // send to console so not invisible
  console.error(error);

  // Send to Sentry
  captureException(error);
}
