import axios, { AxiosError, isAxiosError } from 'axios'; // AxiosResponse, AxiosError avail

import { showToast } from 'web/utils/toast';
import { queryKeys } from './queryKeys';
import { reactQueryClient } from 'web/App';
import { OptionalString } from 'web/utils/types';
import trackError from 'shared/utils/errors/track-error';

export const api = axios.create({
  baseURL: '/api',
});

export const API_ERRORS = {
  NOT_FOUND: 404,
  FORBIDDEN: 403,
};

/**
 *  https://axios-http.com/docs/res_schema
 *
 *  axiosResponseSchema = {
 *    data: {}, `data` is the response that was provided by the server
 *    status: 200, `status` is the HTTP status code from the server response
 *    statusText: 'OK', `statusText` is the HTTP status message from the server respons // As of HTTP/2 status text is blank or unsupported.
 *    headers: {}, `headers` the HTTP headers that the server responded with
 *    config: {}, `config` is the config that was provided to `axios` for the request
 *    request: {} `request` is the request that generated this response
 *  }
 */

export type ApiError = AxiosError | Error | unknown;
export type ApiErrorHandler = (err: ApiError) => void;

const genericErrorResponse = () => showToast({ message: 'An error occurred', type: 'error' });

export const invalidateBookCircle = (circleId: OptionalString, bookId: OptionalString) => {
  if (!(circleId && bookId)) {
    return;
  }

  reactQueryClient.invalidateQueries([queryKeys.annotations.index, bookId, circleId]);
  reactQueryClient.invalidateQueries([queryKeys.bookCircleFeed.annotations, circleId, bookId]);
  reactQueryClient.invalidateQueries([queryKeys.bookCircleFeed.replies, circleId, bookId]); // by newest
  reactQueryClient.invalidateQueries([queryKeys.replies.grouped, circleId, bookId]); // by reader
  reactQueryClient.invalidateQueries([queryKeys.bookCircles.show, circleId, bookId]);
};

/**
 * Tolerable default behavior for user-facing errors
 */
export const defaultErrorHandler: ApiErrorHandler = err => {
  trackError(err);

  if (isAxiosError(err)) {
    if (err.message === 'Network Error') {
      showToast({
        message: "Network Error: please ensure you're connected to the network",
        type: 'error',
      });
    } else if (typeof err.response?.data?.error === 'string') {
      showToast({
        message: err.response.data.error,
        type: 'error',
      });
    } else {
      genericErrorResponse();
    }
  } else {
    genericErrorResponse();
  }
};

/**
 * Wrapper for async actions that need error handling
 * Allows for custom error handling that can bubble up to default handling
 * @param actionFn | async action to perform
 * @param customErrorHandler | custom handler that can bypass or bubble up to default error handling
 */
export const withAsyncErrorHandling = async <T>(
  actionFn: () => Promise<T>,
  options?: { customErrorHandler?: ApiErrorHandler },
) => {
  try {
    // perform the action and return the result
    return await actionFn();
  } catch (err) {
    if (options?.customErrorHandler) {
      try {
        options.customErrorHandler(err);
        // use throw error in customErrorHandler to bubble up to default error handling
      } catch (e) {
        defaultErrorHandler(err);
      }
    } else {
      defaultErrorHandler(err);
    }
  }
};
